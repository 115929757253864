<template>
  <div>
    {{ section }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            sections: 'sanitaire/sections'
        }),
        section(){
            let s = this.sections.find(item => item.uid === this.object.section)
            if(s) return s.libelle
            return '-'
        }
    }

}
</script>

<style>

</style>